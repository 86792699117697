<template>
  <!-- 拣货任务 -->
  <div class="app-container">
    <el-form ref="inboundForm" :model="inboundForm" label-width="120px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="运输单号">
              <el-input v-model="inboundForm.transportId" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发出仓">
              <el-select v-model="inboundForm.fromLogicWarehouseCode" :placeholder="$t('page.selectPlaceholder')" filterable clearable>
                <el-option
                  v-for="item in warehouseList"
                  :key="item.logicWarehouseCode"
                  :value="item.logicWarehouseCode"
                  :label="item.logicWarehouseName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="目的仓">
              <el-select v-model="inboundForm.toLogicWarehouseCode" :placeholder="$t('page.selectPlaceholder')" filterable clearable>
                <el-option
                  v-for="item in warehouseList"
                  :key="item.logicWarehouseCode"
                  :value="item.logicWarehouseCode"
                  :label="item.logicWarehouseName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="目的仓platSKU">
              <el-input v-model="inboundForm.platSku" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="SKU">
              <el-input v-model="inboundForm.sku" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="承运商">
              <el-input v-model="inboundForm.vendorCode" clearable />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item label="导入时间">
              <div style="display:flex">
                <el-date-picker
                  v-model="inboundForm.transportDate"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="~"
                  :start-placeholder="$t('title.starttime')"
                  :end-placeholder="$t('title.endtime')"
                />
              </div>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24" style="display:flex;height:36px;">
            <el-button type="primary" @click="handleExport">导出</el-button>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="getShipmentList()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      ref="tableData"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="transportId" label="运输单号" align="center">
        <!-- <template slot-scope="scope">
          <router-link :to="{ append:true, path: 'details', query: { row:JSON.stringify(scope.row) }}">
            <el-link :underline="false" type="primary">{{ scope.row.transportId }}</el-link>
          </router-link>
        </template> -->
      </el-table-column>
      <el-table-column prop="sku" label="SKU" align="center" />
      <el-table-column prop="brandName" label="品牌" align="center" />
      <el-table-column prop="style" label="STYLE" align="center" />
      <el-table-column prop="platSku" label="目的仓PlAtSKU" align="center" />
      <el-table-column prop="fromLogicWarehouseName" label="发出仓" align="center" />
      <el-table-column prop="toLogicWarehouseName" label="目的仓" align="center" />
      <el-table-column prop="vendorName" label="运输服务商" align="center" />
      <el-table-column prop="onWayPairs" label="在途数量" align="center" />
      <el-table-column prop="createTime" label="创建时间" align="center" />
    </el-table>
    <Paging :pager="pager" @update="update" />
  </div>
</template>
<script>
import { getOnwayTask, getWarehouseLogictWarehouse, postOnwayTask } from '@/api/inventory-result'
import Paging from '@/components/Paging'
import { Mixin } from '@/mixin/mixin.js'

export default {
  name: 'TransitManage',
  components: { Paging },
  mixins: [Mixin],
  data() {
    return {
      labelsNum: 0,
      inboundForm: {
        transportId: '',
        shipmentId: '',
        transportDate: [],
        sku: '',
        vendorCode: ''
      },
      showRow: false, // 切换显示input
      tableLoading: false,
      tableDatas: [],
      warehouseList: [],
      pager: {
        size: 20,
        current: 1,
        total: 0
      }
    }
  },
  computed: {
    queryParams() {
      // const { transportDate = [] } = this.inboundForm
      // const [importTimeStart, importTimeEnd] = transportDate || []
      return Object.assign({}, this.pager, this.inboundForm)
    }
  },

  mounted() {
    this.getShipmentList()
    this._getWarehouseLogictWarehouse()
  },
  methods: {
    async handleExport() {
      postOnwayTask({ exportKey: 'ims_on_way_date', params: JSON.stringify({ ...this.queryParams, pages: 999999 }) }).then(result => {
        if (result.datas) {
          this.$message.success('异步导出成功')
        }
      })
    },
    resetClick(val) { // 点击重置清空文本框信息
      this.inboundForm = {
        shipmentId: '',
        transportDate: []
      }
      this.getShipmentList(1)
    },
    showRowClick() { // 点击展开控制文本框的显示隐藏
      this.showRow = !this.showRow
    },
    async getShipmentList(flag) {
      try {
        this.loading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records }} = await getOnwayTask(this.queryParams)
        this.pager = pager
        this.tableDatas = records
      } finally {
        this.loading = false
      }
    },
    update(val) {
      this.pager = val
      this.getShipmentList()
    },
    async _getWarehouseLogictWarehouse(val) {
      try {
        const { datas } = await getWarehouseLogictWarehouse({
          cargoOwnerCode: '',
          id: 0,
          notCargoOwnerCode: '',
          warehouseCode: ''
        })
        this.warehouseList = datas
      } catch {
        console.error('err')
      }
    }
  }
}
</script>

